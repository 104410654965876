export const ourWorkContentJson = [
  {
    id: 1,
    title: "A controlled-response ChatBot micro-service",
    googleAnalyticsTitle: "ChatBot Micro-Service",
    description:
      "We created a complete ChatBot from scratch for a partner. This ChatBot has pre-defined messages and responses. Users can complete multiple actions on their app using this ChatBot, so this deals with the database quite heavily. -- A comment we got on our implementation from our partner s backend lead:",
    content: `<p>We created a complete ChatBot from scratch for a partner. This ChatBot has pre-defined messages and responses. Users can complete multiple actions on their app using this ChatBot, so this deals with the database quite heavily. -- A comment we got on our implementation from our partner s backend lead:</p>
        <p>You guys have made the entire backend pretty reusable. You re driving the entire Chatbot from the backend This has made integration of ChatBot with our iOS and Android like a breeze. And the white labeling has also become very smooth because of this structure</p>
        <ul>
            <li>A couple of our devs are working with one of our current partners to develop a ChatBot from scratch.</li>
            <li>Were using technologies MongoDB , NodeJs, Firebase , Kafka, and ReactJs (to align with our partners tech stack)</li>
            <li>Our devs discussed and designed all the database schemas required, created all the REST APIs , created the message queue with Kafka to produce/consume the updates, etc.</li>
            <li>They set up Firebase and cron scripts to send push notifications along.</li>
            <li>They also created the complete UI ( more than 50 components ) for ChatBot in React as PWA , to be served in their native Android and iOS apps. Also did deep linking to open any native screen from the chatbot, or get back to the chatbot from any native screen.</li>
            <li>We are also capturing all the metadata of user interaction with ChatBot.</li>
        </ul>`,
    tags: ["FULL-STACK", "MICRO-SERVICE", "REACT-PWA"],
  },
  {
    id: 2,
    title: "A component library specifically for Magento applications",
    googleAnalyticsTitle: "Magento Applications Library",
    description:
      "We recently completed the delivery of a large component library for one of our current partners. These components are created for applications built with Magento.",
    content: `<p>We recently completed the delivery of a large component library for one of our current partners. These components are created for applications built with Magento.</p>
        <ul>
            <li>This library has more than 60 functional UI components.(** Functional components: components which also have the API layer, along with the Presentational and Logical layer.)</li>
            <li>The components' UI is created using React, Typescript, and TailwindCSS.</li>
            <li>And the API layer uses GraphQL with Apollo.</li>
            <li>We used Storybook to document and share the components. Leveraged package linking with npm link for local development. And each component was added with its own wiki as well in form of a Confluence doc.</li>
            <li>Also were added with the components, a huge amount of tests.</li>
            <li>We started with Unit tests and Integration tests for each component using Jest.</li>
            <li>But later discussed having Snapshot testing for a few components as well (the first time ever we worked on this).</li>
            <li>Regression tests were also added for most of the components at a later stage of development.</li>
            <li>The final test coverage was 85% at the time of handover.</li>
            <li>The entire development process took 12 sprints (around 6 months) with 3 of our developers working full-time.</li>
        </ul>`,
    tags: ["FULL-STACK", "MICRO-SERVICE", "REACT-PWA"],
  },
];

import { createContext, useState, useEffect } from "react";
import Navbar from "./components/navbar/Navbar";
import { HowWeWork } from "./components/HowWeWork/HowWeWork";
import Footer from "./components/footer/Footer";
import ContactUs from "./components/ContactUs/ContactUs";
import Modal from "./components/Modal/Modal";
import WhoAreWe from "./components/WhoAreWe/WhoAreWe";
import { PreviousClient } from "./components/PreviousClient/PreviousClient";
// import OurExpertise from "./components/OurExpertise/OurExpertise";
import Home from "./components/Home/Home";
import HowWeEnsureQuality from "./components/HowWeEnsureQuality/HowWeEnsureQuality";
import OurWork1 from "./components/OurWork1/OurWork1";
import Content from "./components/OurWork/OurWork";
import ConnectUs from "./components/ConnectUs/ConnectUs";
import ReactGA from "react-ga4";

export const ThemeContext = createContext(null);

function App() {
  const [show, setShow] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const onClose = () => setShow(false);
  const [theme, setTheme] = useState("dark");
  const [icon, setIcon] = useState("sun");

  // Google Analytics Id
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  ReactGA.initialize(googleAnalyticsId);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      setIcon("sun");
    } else {
      setTheme("light");
      setIcon("moon");
    }
  };

  useEffect(() => {
    ReactGA._gaCommandSendPageview(document.location.pathname);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className={`app-container ${theme}`}>
        <div className={`${show ? "modal-open" : ""}`}>
          <Navbar toggleTheme={toggleTheme} icon={icon} />

          {/* <HeroSection /> */}
          <Home />
          <Content />
          {/* <HowWeWork /> */}
          <OurWork1
            showFullContent={showFullContent}
            setShowFullContent={setShowFullContent}
          />
          <WhoAreWe />
          <PreviousClient />
          <ConnectUs />
          <HowWeWork />
          {/* <PreviousWorkExamples /> */}
          {/* <OurExpertise /> */}
          <HowWeEnsureQuality
            showFullContent={showFullContent}
            setShowFullContent={setShowFullContent}
          />
          <ContactUs show={show} setShow={setShow} />
          <Footer theme={theme} />
        </div>
        {show ? (
          <Modal
            onClose={onClose}
            setShow={setShow}
            children={<>Social Media</>}
            show={show}
          />
        ) : (
          ""
        )}
      </div>
    </ThemeContext.Provider>
  );
}

export default App;

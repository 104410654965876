export const footerGoogleAnalyticsButtonObj = {
  FooterTwitterButton: {
    action: "Footer Twitter Button",
    label: "clicked on footer twitter button",
  },
  FooterDribbbleButton: {
    action: "Footer Dribbble Button",
    label: "clicked on footer dribbble button",
  },
  FooterLinkedInButton: {
    action: "Footer LinkedIn Button",
    label: "clicked on footer linkedIn button",
  },
  FooterPinterestButton: {
    action: "Footer Pinterest Button",
    label: "clicked on footer pinterest button",
  },
  FooterInstagramButton: {
    action: "Footer Instagram Button",
    label: "clicked on footer instagram button",
  },
  FooterThreadButton: {
    action: "Footer Thread Button",
    label: "clicked on footer thread button",
  },
  FooterHomeButton: {
    action: "Footer Home Button",
    label: "clicked on footer home button",
  },
  FooterTeamButton: {
    action: "Footer Our Team Button",
    label: "clicked on footer our team button",
  },
  FooterWorkButton: {
    action: "Footer Our Work Button",
    label: "clicked on footer our work button",
  },
  FooterServiceButton: {
    action: "Footer Service Button",
    label: "clicked on footer service button",
  },
  FooterPartnerButton: {
    action: "Footer Partner Button",
    label: "clicked on footer partner button",
  },
  FooterOurQualityButton: {
    action: "Footer Our Quality Button",
    label: "clicked on footer our quality button",
  },
  FooterContactButton: {
    action: "Footer Contact Button",
    label: "clicked on footer contact button",
  },
  FooterBookCallButton: {
    action: "Footer Book Call Button",
    label: "clicked on footer book call button",
  },
  FooterSendEmailButton: {
    action: "Footer Send Email Button",
    label: "clicked on footer send email button",
  },
};

export const headerGoogleAnalyticsButtonObj = {
  HeaderHomeButton: {
    action: "Header Home Button",
    label: "clicked on header home button",
  },
  HeaderServiceButton: {
    action: "Header Service Button",
    label: "clicked on header service button",
  },
  HeaderWorkButton: {
    action: "Header Our Work Button",
    label: "clicked on header our work button",
  },
  HeaderTeamButton: {
    action: "Header Our Team Button",
    label: "clicked on header our team button",
  },
  HeaderPartnerButton: {
    action: "Header Partner Button",
    label: "clicked on header partner button",
  },
  HeaderOurQualityButton: {
    action: "Header Our Quality Button",
    label: "clicked on header our quality button",
  },
  HeaderContactButton: {
    action: "Header Contact Button",
    label: "clicked on header contact button",
  },
  SetDarkTheme: {
    action: "Set Dark Theme Button",
    label: "clicked on set dark theme button",
  },
  SetLightTheme: {
    action: "Set Light Theme Button",
    label: "clicked on set light theme button",
  },
};

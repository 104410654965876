export const QualityExamples = [
  {
    title: 'Tests &  <span className="red" >Coverage</span>',
    googleAnalyticsTitle: "Test and Coverage",
    description: "We have worked on 3 projects with TDD.",
    content: `<p>We prioritize a strong testing culture at our software service company, ensuring the delivery of high-quality and reliable software. We have successfully implemented Test-Driven Development (TDD) in three projects. TDD involves writing tests before writing the actual code, ensuring comprehensive test coverage and driving the development process.</p>
    <h3>Test Types:</h3>
    <p>Our team has extensive experience working with various types of tests, including:</p>
    <ol>
      <li><b>Unit tests:</b> These tests focus on testing individual components or functions in isolation to verify their behavior.</li>
      <li><b>Integration tests:</b> We conduct integration tests to validate the interaction and integration between different components or modules of the software.</li>
      <li><b>Snapshot testing:</b> We use snapshot testing to capture and compare the expected output of a component against the current output, ensuring consistency over time.</li>
      <li><b>End-to-end (e2e) testing:</b> This type of testing simulates real user scenarios and validates the entire application flow, ensuring smooth functionality across different layers of the system.</li>
    </ol>
    <h3>Load Testing:</h3>
    <p>In addition to functional tests, we perform load testing to assess the performance and scalability of our APIs and servers. By simulating high traffic and load scenarios, we identify potential bottlenecks and ensure optimal system performance.</p>
    <h3>Coverage Metrics:</h3>
    <p>We place great importance on test coverage. On average, we deliver software with a coverage rate of 75%. Test coverage measures the percentage of code covered by tests, providing insights into the thoroughness of our testing efforts. We strive to achieve a balance between comprehensive coverage and efficient testing.</p>
    <h3>Collaboration with QAs:</h3>
    <p>Our developers collaborate closely with Quality Assurance (QA) teams to ensure the effectiveness of our testing processes. Our developers take up test cases from QAs and perform a dry-run on the testing deployment environment. This helps ensure the sanity of the test cases and identifies any potential issues or gaps early on.</p>
    <p>By employing a combination of TDD, a wide range of test types, load testing, maintaining coverage metrics, and collaborating with QAs, we ensure the delivery of robust and thoroughly tested software solutions.</p>`,
  },
  {
    title: 'Code <span className="green" >Reviews</span>',
    googleAnalyticsTitle: "Code Reviews",
    description:
      "We indulge in thoroughly serious yet non-bullish code reviews.",
    content: `<p>During our code review process at our software service company, we carefully assess the code to ensure its quality, maintainability, and adherence to industry best practices. Here are the key aspects we focus on:</p>
    <ol>
      <li><b>Clarity and Readability:</b>
        <ul>
          <li>Ensuring that the code is clear, concise, and easy to understand.</li>
          <li>Checking if variable and function names are descriptive and meaningful.</li>
          <li>Assessing if the code follows consistent formatting and coding conventions.</li>
        </ul>
      </li>
      <li><b>Functionality and Correctness:</b>
        <ul>
          <li>Verifying that the code implements the desired functionality.</li>
          <li>Checking if it meets the project requirements and specifications.</li>
          <li>Identifying logical errors, edge cases, and potential bugs.</li>
        </ul>
      </li>
      <li><b>Code Structure and Organization:</b>
        <ul>
          <li>Evaluating the overall structure and organization of the codebase.</li>
          <li>Assessing if the code is modular and follows a clear architectural design.</li>
          <li>Identifying opportunities for refactoring to improve code maintainability.</li>
        </ul>
      </li>
      <li><b>Performance and Efficiency:</b>
        <ul>
          <li>Analyzing the code for potential performance bottlenecks or inefficiencies.</li>
          <li>Suggesting optimizations or alternative approaches to enhance execution speed.</li>
          <li>Considering scalability and the impact of the code on system performance.</li>
        </ul>
      </li>
      <li><b>Error Handling and Exception Handling:</b>
        <ul>
          <li>Checking if the code handles errors and exceptions appropriately.</li>
          <li>Verifying that error messages and logging are informative and helpful for debugging.</li>
          <li>Ensuring that error handling mechanisms are in place to prevent application crashes.</li>
        </ul>
      </li>
      <li><b>Testing and Testability:</b>
        <ul>
          <li>Evaluating the presence and effectiveness of unit tests and other relevant test cases.</li>
          <li>Assessing if the code is designed to be easily testable and maintainable.</li>
          <li>Identifying areas where additional test coverage or improvements to the test suite are needed.</li>
        </ul>
      </li>
      <li><b>Security:</b>
        <ul>
          <li>Assessing the code for potential security vulnerabilities and weaknesses.</li>
          <li>Verifying that user inputs are properly validated and sanitized.</li>
          <li>Ensuring that sensitive data is handled securely and protected from unauthorized access.</li>
        </ul>
      </li>
      <li><b>Documentation and Comments:</b>
        <ul>
          <li>Evaluating the quality and completeness of code comments and documentation.</li>
          <li>Checking if the code is self-explanatory or requires additional explanatory comments.</li>
          <li>Encouraging comprehensive documentation to facilitate code understanding and maintenance.</li>
        </ul>
      </li>
      <li><b>Best Practices and Standards:</b>
        <ul>
          <li>Ensuring that the code follows industry best practices and coding standards.</li>
          <li>Evaluating if it adheres to established design patterns and architectural guidelines.</li>
          <li>Identifying areas where code refactoring or improvements are necessary for better code quality.</li>
        </ul>
      </li>
      <li><b>Constructive and Collaborative Feedback:</b>
        <ul>
          <li>Providing constructive feedback to the code author to enhance code quality.</li>
          <li>Offering suggestions, alternative solutions, and improvements.</li>
          <li>Maintaining a positive and collaborative atmosphere during the code review process.</li>
        </ul>
      </li>
    </ol>
    <p>By considering these aspects during our code review process, we ensure that the code meets high standards of quality, functionality, maintainability, and performance.</p>`,
  },
  {
    title:
      'Formatting, <span className="yellow" >Linting</span> & code <span className="green" >comments</span>',
    googleAnalyticsTitle: "Formatting, Linting and comments",
    description:
      "We invariably use code formatters and linters with rules like:",
    content: `<p>
    We emphasize the importance of maintaining clean and consistent code through the use of formatting, linting, and code comments. Here's an explanation of the tools we employ in these areas:
  </p>
  <h3><b>Formatting</b></h3>
  <p>
    We understand the significance of consistent code formatting in enhancing readability and maintainability. To achieve this, we utilize popular code formatting tools like:
  </p>
  <ul>
    <li>Prettier: Prettier is an opinionated code formatter that automatically formats code according to a set of predefined rules. It supports multiple programming languages and ensures code consistency across the entire project.</li>
  </ul>
  <h3>Linting</h3>
  <p>
    Linting helps identify and enforce coding standards, detect potential bugs, and improve code quality. We incorporate the following linting tools into our development workflow:
  </p>
  <ul>
    <li>ESLint: ESLint is a widely adopted linting tool that analyzes JavaScript or TypeScript code for common errors, coding style violations, and potential bugs. It can be customized to match our preferred coding conventions and best practices.</li>
  </ul>
  <h3>Code Comments</h3>
  <p>
    Clear and informative code comments play a crucial role in code comprehension and collaboration. We encourage the use of descriptive comments to explain complex logic, provide context, and document important details. While the specific commenting style may vary depending on the programming language, we emphasize the importance of self-explanatory code that minimizes the need for excessive comments.
  </p>
  <p>
    We recognize that adhering to formatting, linting, and code commenting practices contributes to code readability, consistency, and overall development efficiency. By utilizing tools like Prettier and ESLint, we ensure that our codebase meets high-quality standards, while providing clear and concise code comments to facilitate understanding and maintainability throughout the software development process.
  </p>`,
  },

  {
    title: 'Best <span className="red" >Git</span> Practices',
    googleAnalyticsTitle: "Best Git Practices",
    description:
      "We mostly have the following git hooks set up in all projects",
    content: `<p>At our software service company, we prioritize efficient and streamlined version control using Git. To ensure consistent and high-quality code commits across all projects, we have implemented the following Git hooks:</p>
    <ol>
      <li><b>pre-commit:</b> This hook is executed before a commit is made and allows us to run automated checks on the code. It helps maintain code quality by running linters, unit tests, and other validations to catch potential issues early on.</li>
      <li><b>prepare-commit-msg:</b> This hook allows customization or preparation of the commit message before it is finalized. It can be used to automatically add information, such as issue numbers or tags, to the commit message based on the branch or other contextual information.</li>
      <li><b>commit-message:</b> This hook validates the commit message format and enforces any specific guidelines or conventions we follow. It ensures that commit messages are descriptive, informative, and easy to understand, aiding in better project collaboration and code review processes.</li>
      <li><b>post-commit:</b> This hook is executed after a commit is made and allows us to perform additional actions or processes. It can be used for tasks like triggering notifications, generating documentation, or performing post-commit code analysis.</li>
    </ol>
    <p>In addition to our Git hooks, we have extensive experience working with various branch strategies, adapting to the needs of each project. Whether it's the commonly used Git Flow, feature branching, or any other branching model, our team is well-versed in handling branching and merging effectively.</p>
    <p>Our developers are proficient in advanced Git commands, including:</p>
    <ul>
      <li><b>git merge:</b> Used to combine changes from different branches into the current branch</li>
      <li><b>git rebase:</b> Allows for integrating changes from one branch into another by moving or modifying commits</li>
      <li><b>git fetch:</b> Retrieves the latest changes from a remote repository without merging them</li>
      <li><b>git cherry-pick:</b> Selectively applies specific commits from one branch to another, enabling precise code integration.</li>
    </ul>
    <p>Furthermore, we have expertise in leveraging GitHub Action scripts, which enable powerful automation and continuous integration/continuous deployment (CI/CD) workflows. By leveraging GitHub Actions, we can automate various development tasks, such as building, testing, and deploying software, ensuring smooth and efficient software development pipelines.</p>
    <p>Our utilization of Git hooks, branch strategies, advanced Git commands, and GitHub Action scripts showcases our commitment to efficient collaboration, code quality, and automation in managing software projects.</p>`,
  },
  {
    title: '<span className="yellow" >Documentation</span>',
    googleAnalyticsTitle: "Documentation",
    description:
      "We are accustomed to adding implementation docs, wikis, readmes etc",
    content: `<p>
    We highlight the significance of comprehensive and well-maintained documentation in our software development process. Here's an explanation of the tools we utilize and the documentation practices we follow:
  </p>
  <h3>Documentation Tools</h3>
  <p>
    We employ various documentation tools to facilitate efficient knowledge sharing and collaboration:
  </p>
  <ul>
    <li><strong>Confluence:</strong> Confluence is a popular team collaboration tool that enables us to create, organize, and share project documentation, meeting notes, and technical specifications.</li>
    <li><strong>Notion:</strong> Notion is a flexible all-in-one workspace that allows us to create and organize documentation, project roadmaps, and internal knowledge bases.</li>
    <li><strong>Jama:</strong> Jama is a requirement management platform that assists us in capturing and managing project requirements, ensuring clarity and traceability throughout the software development lifecycle.</li>
    <li><strong>GitHub Wikis:</strong> GitHub Wikis enable us to maintain documentation directly within our code repositories, making it easily accessible to developers and contributors.</li>
    <li><strong>Readme Files:</strong> Readme files are essential components of our code repositories, containing information about the project, installation instructions, and usage guidelines.</li>
  </ul>
  <h3>Acceptance Criteria</h3>
  <p>
    We emphasize the importance of clearly defined acceptance criteria for every task or feature. Acceptance criteria outline the specific conditions that need to be met for a user story or feature to be considered complete. We incorporate acceptance criteria into our project management tool, such as Jira, to ensure a shared understanding among stakeholders, developers, and QA teams.
  </p>
  <h3>Reverse Brief</h3>
  <p>
    Our developers contribute to the documentation process by adding a "reverse brief" to project documentation. A reverse brief is a summary or overview provided by the developer, outlining the technical approach, design decisions, and implementation details related to a specific feature or task. This helps maintain a comprehensive record of the development process and facilitates knowledge transfer within the team.
  </p>
  <p>
    By utilizing tools like Confluence, Notion, Jama, GitHub Wikis, and Readme files, we ensure that documentation remains up-to-date, easily accessible, and well-organized. Incorporating acceptance criteria in Jira and including reverse briefs provided by developers further enhances our documentation practices, promoting collaboration, clarity, and effective knowledge sharing within our software development projects.
  </p>`,
  },
  {
    title: 'Follow Best <span className="green" >Coding</span> Practices',
    googleAnalyticsTitle: "Follow Best Coding Practices",
    description: "ES6 syntax, Readability, DRY",
    content: `<p>
    In our software service company, we are committed to following best coding practices to ensure high-quality and maintainable code. Here are some of the key practices we adhere to:
  </p>
      <h3>ES6 Syntax</h3>
      <p>
        We embrace the modern features and enhancements introduced in ECMAScript 6 (ES6) and utilize them in our coding practices. ES6 offers improved syntax and new language features that help enhance code readability, maintainability, and overall developer productivity.
      </p>
      <h3>Readability</h3>
      <p>
        We emphasize the importance of writing code that is easy to read and understand. Our developers follow clear and consistent naming conventions for variables, functions, and classes. We also prefer writing self-explanatory code by using meaningful comments and avoiding overly complex or convoluted logic.
      </p>
      <h3>DRY (Don't Repeat Yourself)</h3>
      <p>
        We strive to avoid code duplication by adhering to the DRY principle. This means that instead of duplicating code, we aim to modularize and reuse code wherever possible. By promoting code reuse, we enhance code maintainability, reduce the chances of introducing bugs, and improve overall development efficiency.
      </p>
  <p>
    By following these best coding practices, we ensure that our codebase is clean, efficient, and easier to maintain. It results in higher productivity, better collaboration, and a foundation for scalable software solutions.
  </p>`,
  },
  {
    title: 'Follow <span className="red">Patterns</span>',
    googleAnalyticsTitle: "Follow Patterns",
    description: "React Patterns, JS/TS Patterns, Nodejs Patterns",
    content: `<p>To ensure consistent and efficient development practices, our software service company emphasizes the importance of following established patterns in various areas of software development. Here are some key patterns we focus on:</p>
    <h3>React Patterns</h3>
    <ul>
      <li>React patterns provide guidelines and best practices for developing applications using the React library.</li>
      <li>We emphasize patterns such as Container-Component pattern, Higher-Order Component (HOC) pattern, and Render Props pattern to enhance component reusability and maintainability.</li>
      <li>By adhering to React patterns, we ensure cleaner code structure, improved state management, and efficient component composition.</li>
    </ul>
    <h3>JS/TS Patterns</h3>
    <ul>
      <li>JavaScript (JS) and TypeScript (TS) patterns establish conventions for writing scalable and maintainable code.</li>
      <li>We follow patterns such as Module pattern, Singleton pattern, and Observer pattern to enhance code organization and modularity.</li>
      <li>By leveraging these patterns, we ensure code reusability, reduce coupling, and enhance the overall maintainability of our projects.</li>
    </ul>
    <h3>Node.js Patterns</h3>
    <ul>
      <li>Node.js patterns provide guidance for building scalable and performant applications using the Node.js runtime environment.</li>
      <li>We adopt patterns such as the Middleware pattern, Callback pattern, and Promises pattern to handle asynchronous operations effectively.</li>
      <li>By following Node.js patterns, we ensure optimized I/O operations, better error handling, and improved code structure in our backend development.</li>
    </ul>
    <h3>Design Patterns</h3>
    <ul>
      <li>Design patterns offer proven solutions to common software design problems, promoting code reuse and maintainability.</li>
      <li>We employ various design patterns such as Singleton pattern, Factory pattern, and Observer pattern to tackle specific challenges in our projects.</li>
      <li>By applying design patterns, we enhance the flexibility, extensibility, and testability of our software architecture.</li>
    </ul>
    <p>By adhering to React patterns, JS/TS patterns, Node.js patterns, and design patterns, we establish consistent coding practices, enhance code maintainability, and foster collaboration within our development teams. These patterns enable us to deliver high-quality, scalable, and maintainable software solutions to our clients.</p>`,
  },
];

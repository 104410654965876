import React from "react";

const ConnectOption = ({ link, image, alt, text, type }) => {
  const optionClassName = type === "EMAIL" ? "email-option" : "phone-option";
  const isFiverrOption = type === "FIVERR";

  return (
    <a href={link} target="next">
      <div className={optionClassName}>
        <div className="image-container">
          <img
            style={{ height: isFiverrOption ? "78px" : "auto" }}
            src={image}
            alt={alt}
          />
        </div>
        <p>
          {text}
          {isFiverrOption && <span style={{ color: "#40CA89" }}>Fiverr</span>}
        </p>
      </div>
    </a>
  );
};

export default ConnectOption;

import {
  Twitter,
  Dribbble,
  Pinterest,
  Thread,
  LinkedIn,
  Instagram,
} from "./Icons";

const navbarLinks = [
  {
    name: "Home",
    link: "#home",
    analyticsInfo: "HeaderHomeButton",
  },
  {
    name: "Services",
    link: "#our-services",
    analyticsInfo: "HeaderServiceButton",
  },
  {
    name: "Our Work",
    link: "#our-work",
    analyticsInfo: "HeaderWorkButton",
  },
  {
    name: "Team",
    link: "#our-team",
    analyticsInfo: "HeaderTeamButton",
  },
  {
    name: "Partners",
    link: "#our-partners",
    analyticsInfo: "HeaderPartnerButton",
  },
  {
    name: "Contact Us",
    link: "#connect-us",
    analyticsInfo: "HeaderContactButton",
  },
];

export default navbarLinks;

export const footerSocialHandleLinksInfo = {
  FooterTwitterIcon: {
    action: "Footer Twitter Button",
    label: "clicked on footer twitter button",
  },
  FooterDribbbleIcon: {
    action: "Footer Dribbble Button",
    label: "clicked on footer dribbble button",
  },
  FooterLinkedInIcon: {
    action: "Footer LinkedIn Button",
    label: "clicked on footer linkedIn button",
  },
  FooterPinterestIcon: {
    action: "Footer Pinterest Button",
    label: "clicked on footer pinterest button",
  },
  FooterInstagramIcon: {
    action: "Footer Instagram Button",
    label: "clicked on footer instagram button",
  },
  FooterThreadIcon: {
    action: "Footer Thread Button",
    label: "clicked on footer thread button",
  },
};

export const footerSocialHandleLinks = {
  Twitter: {
    link: "https://twitter.com/Priyam_Inn",
    title: "Twitter",
    Component: Twitter,
    size: "20",
    analyticsInfo: "FooterTwitterIcon",
  },
  Dribbble: {
    link: "https://dribbble.com/PriyamInnovations",
    title: "Dribbble",
    Component: Dribbble,
    size: "23",
    analyticsInfo: "FooterDribbbleIcon",
  },
  LinkedIn: {
    link: "https://www.linkedin.com/company/77714375/admin/feed/posts/",
    title: "LinkedIn",
    Component: LinkedIn,
    size: "19",
    analyticsInfo: "FooterLinkedInIcon",
  },
  Pinterest: {
    link: "https://in.pinterest.com/priyaminnovations",
    title: "Pinterest",
    Component: Pinterest,
    size: "20",
    analyticsInfo: "FooterPinterestIcon",
  },
  Instagram: {
    link: "https://www.instagram.com/priyam_innovations/",
    title: "Instagram",
    Component: Instagram,
    size: "20",
    analyticsInfo: "FooterInstagramIcon",
  },
  Thread: {
    link: "https://www.threads.net/@priyam_innovations_",
    title: "Thread",
    Component: Thread,
    size: "21",
    analyticsInfo: "FooterThreadIcon",
  },
};

import React from "react";
import "./CardContent.css";
import { ourWorkContentJson } from "../OurWork1/ourWorkContentJson";
import { QualityExamples } from "../../HowWeEnsureQualityExample";
import JsxParser from "react-jsx-parser";

const CardContent = ({ setShowFullContent }) => {
  const cardContentJson = JSON.parse(localStorage.getItem("cardFullContent"));
  let content;

  if (cardContentJson.type === "OUR_WORK") {
    content = ourWorkContentJson[cardContentJson?.id - 1];
  } else if (cardContentJson.type === "QUALITY_CARD") {
    content = QualityExamples[cardContentJson.id];
  }

  return (
    <div>
      <div className="back-btn">
        <p onClick={() => setShowFullContent(false)}>&lt; Back</p>
      </div>
      <h2>{<JsxParser jsx={content?.title} />}</h2>
      {cardContentJson.showTag && (
        <div className="card-tags">
          <p>FULL-STACK</p>
          <p>MICRO-SERVICE</p>
          <p>REACT-PWA</p>
        </div>
      )}
      <div className="card-content">
        <JsxParser jsx={content?.content} />
      </div>
    </div>
  );
};

export default CardContent;

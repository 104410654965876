import React, { useEffect } from "react";
import "./OurWork.css";
import ReactGA from "react-ga4";

function Content() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        ReactGA.event({
          category: "Component Visibility",
          action: "What do we do Section",
        });
      }
    });

    observer.observe(document.getElementById("our-services"));

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div id="our-services" className="our-work-container">
      <div className="container">
        <h1>What do we do?</h1>
        <div className="card-container">
          <img className="left" src="Images/what-do-we-do-2.webp" alt="" />
          <h2 className="right">
            We’re experienced in desigining, developing, and scaling
            <span className="aqua"> Web Apps</span> and native apps for{" "}
            <span className="red">Android</span> and{" "}
            <span className="green">iOS</span>.
          </h2>
        </div>

        <div className="card-container reverse">
          <img className="right" src="Images/what-do-we-do-1.webp" alt="" />
          <h2 className="left">
            We can create <span className="aqua">mesmerising</span>,{" "}
            <span className="red">functional</span>, and{" "}
            <span className="yellow">responsive UIs.</span>
          </h2>
        </div>

        <div className="card-container">
          <img className="left" src="Images/what-do-we-do-3.webp" alt="" />
          <h2 className="right">
            We can build APIs which can handle tons of{" "}
            <span className="green">concurrent requests</span>, and are{" "}
            <span className="red">highly secure</span>
          </h2>
        </div>

        <div className="card-container reverse">
          <img className="right" src="Images/what-do-we-do-4.webp" alt="" />
          <h2 className="left">
            Can work with <span className="aqua">databases</span> for{" "}
            <span className="yellow">data modelling</span>,{" "}
            <span className="red">normalization</span>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Content;

import React from "react";
import "./WhoAreWe.css";
const WhoAreWe = () => {
  return (
    <div className="who-are-we-container" id="our-team">
      <div className="container">
        <h1>Who are we?</h1>
        <div className="who-are-we">
          <div className="who-are-we-image">
            <img src="Images/our-team-2.webp" alt="Team Img" />
          </div>
          <div className="who-are-we-content ">
            <div className="who-are-we-section ">
              <h2>
                We are a small but <span className="red">energetic</span> team
                of <span className="green"> software</span> developers.
              </h2>
            </div>
            <div className="who-are-we-section">
              <h2>
                Everyone is <span className="yellow"> experienced</span> between{" "}
                <span className="red"> 3 to 6 </span> years.
              </h2>
            </div>
            <div className="who-are-we-section">
              <h2>
                Each of us works across the{" "}
                <span className="blue"> tech stack.</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoAreWe;

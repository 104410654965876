import React from "react";
import "./OurWork1.css";
import OurWorkCard from "../OurWorkCard/OurWorkCard";
import CardContent from "../CardContent/CardContent";
import { ourWorkContentJson } from "./ourWorkContentJson";

const OurWork1 = ({ showFullContent, setShowFullContent }) => {
  return (
    <div id="our-work" className="our-work-container">
      <div className="container">
        <h1>Our Work</h1>
        <div className="cards">
          {ourWorkContentJson.map((content, index) => {
            return (
              <OurWorkCard
                key={index}
                setShowFullContent={setShowFullContent}
                content={content}
              />
            );
          })}
        </div>
      </div>
      {showFullContent && (
        <div className="full-content-container">
          <div className="content-container">
            <CardContent setShowFullContent={setShowFullContent} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OurWork1;

import React from "react";
import "./HowWeWork.css";

const HowWeWork = () => {
  return (
    <div className="how-we-work-section" id="how-we-work-section">
      <div className="container">
        <h1>How do we work?</h1>
        <h2>
          We Believe <span className="expanded-red">'Clarity Is Power'</span>
        </h2>
        <h2>
          And We Also Understand <span className="green">'Time Is Money'</span>
        </h2>
        <h4>
          We have only 3 steps to go from connection to starting development.
        </h4>
        <div className="work-cards">
          <div className="work-card">
            <img src="Images/how-do-we-work-2.svg" alt="" />
            <div>
              <h3>Connect With Us</h3>
            </div>
            <ol>
              <li>Book a call on Calendly or Send Mail to us</li>
            </ol>
          </div>
          <div className="work-card">
            <img src="Images/how-do-we-work-1.svg" alt="" />
            <div>
              <h3>
                The <span className="yellow">'Know it all'</span> Call
              </h3>
            </div>
            <ol>
              <li>
                Know about us, tell about yourself, and everything about the
                Project
              </li>
              <li>
                Our first call, mostly about requirements usually 30 mins long
              </li>
            </ol>
          </div>
          <div className="work-card">
            <img src="Images/how-do-we-work-3.svg" alt="" />
            <div>
              <h3>
                The <span className="green">'Requirements Refinements'</span>{" "}
                Call
              </h3>
            </div>
            <ol>
              <li>
                This will be the first of many. We will discuss more clearly on
                the requirements, designs, developer questions etc. Usually a 30
                mins long call.
              </li>
            </ol>
          </div>
          <div className="work-card">
            <img src="Images/how-do-we-work-4.svg" alt="" />
            <div>
              <h3>
                <span className="blue">Let&apos;s Start Work</span>
              </h3>
            </div>
            <ol>
              <li>First Day of Development Starts</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HowWeWork };

import "./Navbar.css";
import React, { useRef, useEffect } from "react";
import navbarLinks from "../../constant";
import logo from "./assets/Logo.png";
import { headerGoogleAnalyticsButtonObj } from "../../Data/googleAnalyticsJson";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";

function Navbar({ toggleTheme, icon }) {
  const navRef = useRef();
  const mobileMenuRef = useRef();

  const handleMenu = () => {
    const mobileMenuClass = mobileMenuRef.current.className;
    mobileMenuRef.current.className =
      mobileMenuClass === "mobile-menu active"
        ? "mobile-menu"
        : "mobile-menu active";
  };

  useEffect(() => {
    let lastScrollTop = 0;
    function handleHeaderScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        navRef.current.style.top = "-120px";
      } else {
        if (st < 3) {
          navRef?.current?.classList?.remove("navbarUp");
        } else {
          navRef.current.classList.add("navbarUp");
        }
        navRef.current.style.top = "0px";
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }

    window.addEventListener("scroll", handleHeaderScroll);
    return () => {
      window.removeEventListener("scroll", handleHeaderScroll);
    };
  });

  return (
    <>
      <div className="navbar" ref={navRef}>
        <div className="navbar-left">
          <div className="logo">
            {/* <img src="Images/icons/logo.png" alt="logo" /> */}
            <img src={logo} alt="logo" />
            <h5 className="logo-title">PRIYAM INNOVATIONS</h5>
          </div>
        </div>
        <div className="right-wrapper">
          <div className="navbar-right">
            <ul>
              {navbarLinks.map((element, index) => {
                return (
                  <li key={index}>
                    <a
                      onClick={() => {
                        googleAnalyticsButton(
                          headerGoogleAnalyticsButtonObj[element.analyticsInfo]
                        );
                      }}
                      href={element.link}
                    >
                      {element.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="switch">
            <i
              onClick={() => {
                googleAnalyticsButton(
                  icon === "sun"
                    ? headerGoogleAnalyticsButtonObj["SetLightTheme"]
                    : headerGoogleAnalyticsButtonObj["SetDarkTheme"]
                );
                toggleTheme();
              }}
              className={`fas fa-${icon}`}
            ></i>
          </div>
          <i className="fas fa-bars menu" onClick={handleMenu}></i>
        </div>
      </div>
      <div className="mobile-menu" ref={mobileMenuRef}>
        <i className="fas fa-times cross-icon" onClick={handleMenu}></i>
        <ul>
          {navbarLinks.map((element, index) => {
            return (
              <li key={index} onClick={handleMenu}>
                <a
                  onClick={() => {
                    googleAnalyticsButton(
                      headerGoogleAnalyticsButtonObj[element.analyticsInfo]
                    );
                  }}
                  href={element.link}
                >
                  {element.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Navbar;

import React from "react";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";

const OurWorkCard = ({ setShowFullContent, content }) => {
  const { googleAnalyticsTitle } = content;

  const handleReadMore = () => {
    googleAnalyticsButton({
      action: `Our Work - ${googleAnalyticsTitle}`,
      label: `Clicked On ${googleAnalyticsTitle} Card`,
    });
    localStorage.setItem(
      "cardFullContent",
      JSON.stringify({ type: "OUR_WORK", id: content?.id, showTag: true })
    );
    setShowFullContent(true);
  };

  return (
    <div className="card-container">
      <h2 id="our-work-card-heading">{content.title}</h2>
      <div className="card-tags">
        <p>FULL-STACK</p>
        <p>MICRO-SERVICE</p>
        <p>REACT-PWA</p>
      </div>
      <div className="work-card-content">
        <p>{content.description}</p>
      </div>
      <div className="read-more-button">
        <button onClick={handleReadMore}>Read More...</button>
      </div>
    </div>
  );
};

export default OurWorkCard;

import React from "react";
import "./Modal.css";

function Modal({ setShow, show }) {
  return (
    <div className="modal" id="modal">
      <i className="far fa-times cross-icon" onClick={() => setShow(!show)}></i>
      <h2 className="after">Contact Us</h2>
      <form>
        <div className="input-divider">
          <div>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input type="text" id="email" />
          </div>
        </div>
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" cols="30" rows="3"></textarea>
        <button>Submit</button>
        <div className="contact-source">
          <i className="fab fa-whatsapp"></i>
          <p>+91 987654321</p>
        </div>
        <div className="contact-source">
          <i className="far fa-envelope"></i>
          <p>priyaminnovation@contact.us</p>
        </div>
        <div className="contact-source">
          <i className="far fa-phone-alt"></i>
          <p>+91 987654321</p>
        </div>
      </form>
    </div>
  );
}

export default Modal;

import React from "react";
import { QualityExamples } from "../../HowWeEnsureQualityExample";
import "./HowDoWeEnsure.css";
import JsxParser from "react-jsx-parser";
import { generateRandomColor } from "../../utils/generateRandomColor";
// import QualityCard from "../QualityCard/QualityCard";
// import "./HowWeEnsureQuality.css";
import "../PreviousWorkExamples/PreviousWorkExamples.css";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";

const HowWeEnsureQuality = ({ showFullContent, setShowFullContent }) => {
  const handleQualityCard = (id, googleAnalyticsTitle) => {
    googleAnalyticsButton({
      action: `Quality Card - ${googleAnalyticsTitle}`,
      label: `Clicked On ${googleAnalyticsTitle} Card`,
    });
    localStorage.setItem(
      "cardFullContent",
      JSON.stringify({ type: "QUALITY_CARD", id, showTag: false })
    );
    setShowFullContent(true);
  };

  return (
    <div id="how-do-we-ensure" className="code-quality-section">
      <div className="container">
        <h1>Our Quality</h1>
        <div className="quality-cards-section">
          {QualityExamples.map((card, index) => {
            const randomColor = generateRandomColor();
            const { googleAnalyticsTitle } = card;
            return (
              <div
                className="quality-card"
                key={index}
                onClick={() => handleQualityCard(index, googleAnalyticsTitle)}
              >
                <div
                  className="color-box"
                  style={{ backgroundColor: randomColor }}
                ></div>
                <div className="content">
                  <h4>{<JsxParser jsx={card?.title} />}</h4>
                  {<JsxParser jsx={card?.description} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HowWeEnsureQuality;

import React, { useEffect } from "react";
import "./PreviousClient.css";
import ReactGA from "react-ga4";

const PreviousClient = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        ReactGA.event({
          category: "Component Visibility",
          action: "Our Partners Section",
        });
      }
    });

    observer.observe(document.getElementById("our-partners"));

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div id="our-partners" className="previous-client-container">
      <div className="container">
        <h1>Our Partners</h1>
        <h2 className="previous-client-subheading-container">
          <span className="previous-client-subheading">
            We have been in the space for more than a{" "}
            <span className="blue">year </span>and{" "}
            <span className="red">worked</span> with some{" "}
            <span className="yellow"> awesome people </span> and{" "}
            <span className="green"> teams </span> like
          </span>
        </h2>
        <div className="previous-client-logos">
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/zenport-logo.svg" alt="" />
          </div>
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/toddle-logo.png" alt="" />
          </div>
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/youshd.png" alt="" />
          </div>
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/soulbound-red.svg" alt="" />
          </div>
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/altsol-logo.png" alt="" />
          </div>
          <div className="previous-client-logo-subcontainer">
            <img src="/Images/byjus_logo_2.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { PreviousClient };

import React from "react";
import "./Home.css";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";

const googleAnalyticsButtonObj = {
  DribbbleButton: {
    action: "DribbbleButton",
    label: "clicked on dribbble buton",
  },
  PinterestButton: {
    action: "PinterestButton",
    label: "clicked on pinterest buton",
  },
};

const Home = () => {
  return (
    <div id="home" className="home-component">
      <div className="content">
        <div className="section design">
          <div className="text">
            <h2 className="main-heading expanded-blue">Design</h2>
            <p className="main-content">
              We <span className="expanded-green"> design</span> your{" "}
              <span className="expanded-red"> imaginations </span>into desktop
              and mobile apps.
            </p>
            <div>
              <div className="social-handle-icons">
                <a
                  onClick={() => {
                    googleAnalyticsButton(
                      googleAnalyticsButtonObj["PinterestButton"]
                    );
                  }}
                  href="https://in.pinterest.com/priyaminnovations/"
                  target="blank"
                >
                  <img
                    src="Images/icons/pinterest-home.svg"
                    alt="pinterest_icon"
                  />
                  <p>Pinterest</p>
                </a>
                <a
                  onClick={() =>
                    googleAnalyticsButton(
                      googleAnalyticsButtonObj["DribbbleButton"]
                    )
                  }
                  href="https://dribbble.com/PriyamInnovations"
                  target="blank"
                >
                  <img src="Images/icons/dribbble-home.svg" alt="" />
                  <p>Dribbble</p>
                </a>
              </div>
            </div>
          </div>
          <div className="image">
            <img src="/Images/design-section-1.webp" alt="Design 1" />
            <img src="/Images/design-section-2.webp" alt="Design 2" />
            <img src="/Images/design-section-3.webp" alt="Design 3" />
          </div>
        </div>
        <div className="section develop">
          <div className="image">
            <img src="/Images/Snap-code1.webp" alt="Develop 1" />
            <img src="/Images/Snap-code2.webp" alt="Develop 2" />
            <img src="/Images/Snap-code1.webp" alt="Develop 3" />
          </div>
          <div className="text">
            <h2 className="main-heading expanded-green"> Develop</h2>
            <p className="main-content">
              Then help you <span className="expanded-blue"> develop </span>
              software that works,
              <span className="expanded-yellow"> always</span>.
            </p>
          </div>
        </div>
        <div className="section scale">
          <div className="text">
            <h2 className="main-heading expanded-red">Scale</h2>
            <p className="main-content">
              We help you <span className="expanded-blue"> scale </span> your
              business to your next{" "}
              <span className="expanded-green"> million </span>customers
            </p>
          </div>
          <div className="image">
            <img src="/Images/scale-section-1.webp" alt="Scale 1" />
            <img src="/Images/scale-section-2.webp" alt="Scale 2" />
            <img src="/Images/scale-section-3.webp" alt="Scale 3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

export const generateRandomColor = () => {
  const randomBetween = (min, max) =>
    min + Math.floor(Math.random() * (max - min + 1));

  var RGBColor1 = randomBetween(0, 255);
  var RGBColor2 = randomBetween(0, 255);
  var RGBColor3 = randomBetween(0, 255);

  const newRandomColor = `rgba(${RGBColor1}, ${RGBColor2}, ${RGBColor3}, 0.35)`;

  return newRandomColor;
};

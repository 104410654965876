import React from "react";
import "./Footer.css";
import ConnectOption from "../ConnectOption/ConnectOption";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";
import { footerGoogleAnalyticsButtonObj } from "../../Data/googleAnalyticsJson";
import {
  footerSocialHandleLinks,
  footerSocialHandleLinksInfo,
} from "../../constant";

const Footer = ({ theme }) => {
  const iconColor = theme === "dark" ? "#fff" : "#000";
  const invertFilter = theme === "dark" ? 0 : 1;
  const footerSocialHandleIcons = Object.keys(footerSocialHandleLinks);

  return (
    <footer>
      <div className="container">
        <div className="footer-left">
          <img
            style={{ filter: `invert(${invertFilter})` }}
            src="Images/Logo.png"
            alt="priyam_innovations_logo"
          />
          <p>PRIYAM INNOVATIONS</p>
          <div className="icons">
            {footerSocialHandleIcons.map((icon, index) => {
              const iconInfo = footerSocialHandleLinks[icon];
              const { link, title, Component, size, analyticsInfo } = iconInfo;
              return (
                <a
                  onClick={() => {
                    googleAnalyticsButton(
                      footerSocialHandleLinksInfo[analyticsInfo]
                    );
                  }}
                  href={link}
                  target="blank"
                  title={title}
                  key={index}
                >
                  <Component fill={iconColor} width={size} height={size} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="footer-center">
          <ul>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterHomeButton"]
                  );
                }}
                href="#home"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterTeamButton"]
                  );
                }}
                href="#our-team"
              >
                Our Team
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterWorkButton"]
                  );
                }}
                href="#our-work"
              >
                Our Work
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterServiceButton"]
                  );
                }}
                href="#our-services"
              >
                Services
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterPartnerButton"]
                  );
                }}
                href="#our-partners"
              >
                Our Partners
              </a>
            </li>
            {/* <li>
              <a href="#our-expertise">Our Expertise</a>
            </li> */}
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterOurQualityButton"]
                  );
                }}
                href="#how-do-we-ensure"
              >
                Our Quality
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  googleAnalyticsButton(
                    footerGoogleAnalyticsButtonObj["FooterContactButton"]
                  );
                }}
                href="#connect-us"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-right">
          <div className="connect-options">
            <div
              onClick={() => {
                googleAnalyticsButton(
                  footerGoogleAnalyticsButtonObj["FooterBookCallButton"]
                );
              }}
            >
              <ConnectOption
                link="https://calendly.com/priyaminnovations"
                text="Book Call"
                image="Images/icons/call.avif"
                alt="phone-connect-option"
                type="PHONE"
              />
            </div>
            <div
              onClick={() => {
                googleAnalyticsButton(
                  footerGoogleAnalyticsButtonObj["FooterSendEmailButton"]
                );
              }}
            >
              <ConnectOption
                link="mailto:contact@priyaminnovations.com"
                text="Send Email"
                image="Images/icons/email.avif"
                alt="email-connect-option"
                type="EMAIL"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

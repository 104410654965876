import React, { useEffect } from "react";
import "./ConnectUs.css";
import ConnectOption from "../ConnectOption/ConnectOption";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";
import ReactGA from "react-ga4";

const googleAnalyticsButtonObj = {
  BookCallButton: {
    action: "BookCallButton",
    label: "clicked on book call buton",
  },
  SendEmailButton: {
    action: "SendEmailButton",
    label: "clicked on send email buton",
  },
};

const ConnectUs = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        ReactGA.event({
          category: "Component Visibility",
          action: "Connect Us Section",
        });
      }
    });

    observer.observe(document.getElementById("connect-us"));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div id="connect-us" className="connect-us-container">
      <div className="container">
        <h1>Connect with us</h1>
        <div className="connect-options">
          <span
            onClick={() => {
              googleAnalyticsButton(googleAnalyticsButtonObj["BookCallButton"]);
            }}
          >
            <ConnectOption
              link="https://calendly.com/priyaminnovations"
              text="Book Call"
              image="Images/icons/call.avif"
              alt="phone-connect-option"
              type="PHONE"
            />
          </span>
          <span
            onClick={() => {
              googleAnalyticsButton(
                googleAnalyticsButtonObj["SendEmailButton"]
              );
            }}
          >
            <ConnectOption
              link="mailto:contact@priyaminnovations.com"
              text="Send Email"
              image="Images/icons/email.avif"
              alt="email-connect-option"
              type="EMAIL"
            />
          </span>
        </div>
        <div className="connect-options" style={{ marginTop: "30px" }}>
          <span
            onClick={() => {
              googleAnalyticsButton(googleAnalyticsButtonObj["BookCallButton"]);
            }}
          >
            <ConnectOption
              link="https://www.fiverr.com/yashpriyam24"
              text="Visit "
              image="Images/fiverr.svg"
              alt="fiverr-connect-option"
              type="FIVERR"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConnectUs;

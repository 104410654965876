import "./ContactUs.css";
import { googleAnalyticsButton } from "../../utils/googleAnalyticsButton";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <>
      <div className="contact-icon">
        <button
          data-tf-slider="BO17E9eG"
          data-tf-position="right"
          data-tf-opacity="100"
          data-tf-iframe-props="title=Get Quote"
          data-tf-auto-close
          data-tf-transitive-search-params
          data-tf-medium="snippet"
          className="contact-type-form"
          onClick={() => {
            googleAnalyticsButton({
              action: "Typeform Contact Us Button",
              label: "clicked on typeform contact us button",
            });
          }}
        >
          <Link to="https://www.fiverr.com/yashpriyam24" target="_blank">
            <img
              className="call"
              src="/Images/icons/chat.svg"
              alt="contact-us"
            />
          </Link>
        </button>
      </div>
    </>
  );
};

export default ContactUs;
